import React, { Component } from 'react';
import styles from './Header.module.css';
import {Link} from 'react-router-dom'

import injectSheet from "react-jss";
import classNames from 'classnames';



class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            language: 'DE',
            menu: 'HOME'
        }
    }

    componentDidMount() {
    }


  render() {



    return (
        <div>
            <div className={styles.root}>
                <div className="wrapper">
                    <div className="appMargin">
                        <div className="grid">
                            <div className="col-12 md:col-5 lg:col-5">
                                <div>
                                    <div className={styles.headerText}>
                                      <span>Ersoy </span>
                                      <span className={styles.headerColor}>Eren</span>
                                    </div>
                                    <div style={{marginTop: -10}}>Softwareentwickler in Frankfurt am Main & Remote</div>
                                </div>
                            </div>
                            <div className="col-12 md:col-7 lg:col-7" style={{display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
                                <div style={{float: 'bottom'}}>
                                    {false && (
                                        <Link
                                            to="/credentials"
                                            className={this.state.menu == 'CREDENTIALS'? classNames(styles.menuItem, styles.selectedMenuItem) : styles.menuItem}
                                            onClick={() => this.setState({menu: 'CREDENTIALS'})}>
                                            REFERENZEN
                                        </Link>
                                    )}
                                    <Link
                                        to="/competencies"
                                        className={this.state.menu == 'COMPETENCIES'? classNames(styles.menuItem, styles.selectedMenuItem) : styles.menuItem}
                                        onClick={() => this.setState({menu: 'COMPETENCIES'})}>
                                        KOMPETENZEN
                                    </Link>
                                    <Link
                                        to="/"
                                        className={this.state.menu == 'HOME'? classNames(styles.menuItem, styles.selectedMenuItem) : styles.menuItem}
                                        onClick={() => this.setState({menu: 'HOME'})}>
                                        HOME
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
        </div>

    )
  }
}

export default Header;