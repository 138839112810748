import React, {Component} from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';


const styles = {
    root: {fontWeight: 600, background: "#000000", color: "#FFFFFF", borderRadius: "4px", padding: 5, fontFamily: "monospace"}
}

class CodeTextArea extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {
        const styles = this.props.classes

        const toHTML = (rows) => {
            let result = ""
            rows.map(row => {
                result += "<div>"
                result += row
                result += "</div>"
            })

            result = result.replace(new RegExp(" ", 'g'), "&nbsp;")
            result = result.replace(new RegExp("=", 'g'), "<text style='color: green'>=</text>")
            result = result.replace(new RegExp(";<br/>", 'g'), "<text style='color: orange;'>;<br/></text>")
            result = result.replace(new RegExp("{", 'g'), "<text style='color: orangered'>{</text>")
            result = result.replace(new RegExp("}", 'g'), "<text style='color: orangered'>}</text>")
            result = result.replace(new RegExp("\\(", 'g'), "<text style='color: orange'>(</text>")
            result = result.replace(new RegExp("\\)", 'g'), "<text style='color: orange'>)</text>")
            result = result.replace(new RegExp("\\,", 'g'), "<text style='color: orange'>,</text>")
            result = result.replace(new RegExp("//", 'g'), "<text style='color: deepskyblue'>//</text>")

            return <div dangerouslySetInnerHTML={{__html: result}}></div>
        }
        if (this.props.rows != null) {
            return (
                <div className={styles.root}>
                    {toHTML(this.props.rows)}
                </div>
            )
        } else {
            return (
                <div className={styles.root}>
                    {this.props.children}
                </div>
            )
        }

    }
}

export default injectSheet(styles)(CodeTextArea);