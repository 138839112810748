import React, {Component, useEffect, useState} from 'react';
import classNames from 'classnames';
import aboutMe from "./myknowledge/img/aboutMe.jpg";
import Services from "./services/Services";
import MyKnowledge from "./myknowledge/MyKnowledge";
import MyCareer from ".//mycareer/MyCareer";
import SocialMediaLinks from "./socialmedialinks/SocialMediaLinks";
import MyProjectListForm from "./myprojectlist/Form";
import RequestAnAppointmentForm from "./requestanappointment/Form";
import styleModule from './Home.module.css';
import EEFadeIn from "../ui/eefadein/EEFadeIn";
import EEDiktatorFrom from "../ui/eediktator/Form";


const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

function Home(props) {

    return (
        <div>
            <div className="wrapper appMargin">
                <center>
                    <br/>

                    <EEFadeIn>
                        <div className={classNames("p-col-8", styles.grid)}>
                            <div style={{width: 256, height: 256, boxShadow: "0 0 0 1px rgba(0,0,0,0.15), 0 20px 40px rgba(0,0,0,0.2)", borderRadius: "49.9%", border: "4px solid #FFFFFF"}}>
                                <img style={{ borderRadius: "49.9%", border: "4px solid #FFFFFF"}} width="100%" src={aboutMe}/>
                            </div>
                        </div>
                    </EEFadeIn>
                    <br/>

                    <div style={{maxWidth: 280}}>
                        <SocialMediaLinks/>
                    </div>
                    <div style={{maxWidth: 480}}>
                        Mein Name ist <b>Ersoy Eren</b>. Ich bin Informatiker und selbstständiger Softwareentwickler mit Sitz in Frankfurt am Main.<br/><br/>
                    </div>
                    <br/>
                    <div className={classNames("p-col-12 p-md-5", styles.grid)}>
                        <table>
                            <tbody>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Berufserfahrung:</td>
                                <td>11 Jahre</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Studium:</td>
                                <td>Informatik B.Sc.</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Ausbildung:</td>
                                <td>Technischer Assistent für Informatk (TAI)</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Einsatzort:</td>
                                <td>Frankfurt am Main</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Remoteeinsatz:</td>
                                <td>Ja, zu 100%</td>
                            </tr>
                            <tr>
                                <td style={{paddingTop: 10}}></td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Geburtsjahr, -ort:</td>
                                <td>1986, Frankfurt am Main</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Familienstand:</td>
                                <td>verheiratet, 2 Kinder</td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Staatsangehörigkeit:</td>
                                <td>deutsch</td>
                            </tr>
                            <tr>
                                <td style={{paddingTop: 10}}></td>
                            </tr>
                            <tr>
                                <td style={{fontWeight: "bold", paddingRight: 5}}>Stundensatz:</td>
                                <td>85€ zzgl. MwSt.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </center>
            </div>
            <br/>
            <div className="wrapper appMargin">
                <center>
                    <div className={styleModule.zitatContainer}>
                        <i>
                            <EEDiktatorFrom/>
                        </i>
                    </div>

                    <br/>

                </center>
            </div>

            <EEFadeIn>
                <div style={{background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,249,255,1) 25%)"}}>
                    <div className="wrapper">
                        <MyKnowledge/>
                    </div>
                </div>
            </EEFadeIn>




            <div style={{background: 'rgba(255,255,255,0.8)'}}>
                <div className="wrapper appMargin">
                    <center>
                        <br/>
                        <div className="grid">
                            <div className="col-12 md:col-4">
                                <EEFadeIn orientation="left">
                                    <div className={styleModule.item}>
                                        <div className={styleModule.itemTop}>
                                            <i className="fa-solid fa-code" style={{fontSize: 64, padding: 20}}/><br/>
                                            Entwickeln Sie mit mir Ihre eigene Software
                                        </div>
                                        <div className={styleModule.itemBottom}>
                                            Ob für die ganze Welt über das Internet oder unternehmensintern - nutzen Sie die Überlegenheit von Individualsoftware gegenüber Standardprodukten.
                                        </div>
                                    </div>
                                </EEFadeIn>
                            </div>
                            <div className="col-12 md:col-4" style={{paddingTop: 20, fontSize: 18}}>
                                <EEFadeIn orientation="bottom">
                                    <div className={styleModule.item}>
                                        <div className={styleModule.itemTop}>
                                            <i className="fa-regular fa-lightbulb" style={{fontSize: 64, padding: 20}}/><br/>
                                            Von Ihrer Idee bis zur Umsetzung und darüber hinaus
                                        </div>
                                        <div className={styleModule.itemBottom}>
                                            Profitieren Sie von einer persönlichen Betreuung über den gesamten Entstehungsprozess. Teilen Sie mit mir die Leidenschaft für Ihre Idee.
                                        </div>
                                    </div>
                                </EEFadeIn>
                            </div>
                            <div className="col-12 md:col-4" style={{paddingTop: 20, fontSize: 18}}>

                                <EEFadeIn orientation="right">
                                    <div className={styleModule.item}>
                                        <div className={styleModule.itemTop}>
                                            <i className="fa-solid fa-clock-rotate-left" style={{fontSize: 64, padding: 20}}/><br/>
                                            Jahrelange Erfahrung gepaart mit aktuellem Software-Wissen
                                        </div>
                                        <div className={styleModule.itemBottom}>
                                            Für eine zielgerichtete Einschätzung Ihrer Anforderungen und die Entwicklung der richtigen Lösung.<br/><br/>
                                        </div>
                                    </div>
                                </EEFadeIn>
                            </div>
                        </div>
                        <br/>
                    </center>
                </div>

                <EEFadeIn>
                    <div style={{background: "radial-gradient(circle, rgba(255,255,255,0.3) 0%, #abfdab 25%)"}}>
                        <MyProjectListForm/>
                    </div>
                </EEFadeIn>
                <EEFadeIn>
                    <div style={{background: 'linear-gradient(180deg, #abfdab 0%, #f9f9f9 35%)', paddingTop: 10, paddingBottom: 10}}>
                        <div className="wrapper"  style={{color: '#ffffff'}}>
                            <MyCareer/>
                        </div>
                    </div>
                </EEFadeIn>


                <div className="wrapper appMargin">
                    <Services/>
                </div>

                <div style={{background: "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(231,249,255,1) 25%)"}}>
                    <div className="wrapper appMargin">
                        <RequestAnAppointmentForm/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;