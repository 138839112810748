import React, { Component } from 'react';
import injectSheet from "react-jss";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";

class CompetenceExpressJs extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>Express Js</h1>

                Express ist ein einfaches Node.js-Framework, das leistungsfähige Features und Funktionen bereitstellt.
                Mithilfe von HTTP-Methoden können schnell leistungsfähigen API's erstellt werdenschnell.

                <h2>Hello World - Projekt erstellen</h2>
                <DescriptionPanel>
                    Projekt Verzeichnis erstellen
                    <CodeTextArea rows={[
                        "mkdir helloworld"
                    ]}/>
                    in den Verzeichnis "helloworld" wechseln
                    <CodeTextArea rows={[
                        "cd helloworld"
                    ]}/>
                    nano package.json
                    <CodeTextArea rows={[
                        "{",
                        "  \"name\": \"einburger-website\",",
                        "  \"version\": \"1.0.0\",",
                        "  \"scripts\": {",
                        "    \"start\": \"node server.js\"",
                        "  },",
                        "  \"dependencies\": {",
                        "    \"body-parser\": \"^1.18.3\",",
                        "    \"express\": \"^4.16.4\"",
                        "  }",
                        "}"
                    ]}/>
                    nano server.js
                    <CodeTextArea rows={[
                        "const express = require('express');",
                        "const bodyParser = require('body-parser');",
                        "<br/>",
                        "const app = express();",
                        "const port = process.env.PORT || 5000;",
                        "<br/>",
                        "app.use(bodyParser.json());",
                        "app.use(bodyParser.urlencoded({ extended: true }));",
                        "<br/>",
                        "app.get('/api/hello', (req, res) => {",
                        "  res.send({ express: 'Hello From Express Ersoy' });",
                        "});",
                        "<br/>",
                        "app.post('/api/world', (req, res) => {",
                        "  console.log(req.body);",
                        "  res.send(",
                        "    `I received your POST request. This is what you sent me: ${req.body.post}`,",
                        "  );",
                        "",
                        "app.listen(port, () => console.log(`Listening on port ${port}`));"
                    ]}/>
                    initialisieren
                    <CodeTextArea rows={[
                        "npm install"
                    ]}/>
                    starten
                    <CodeTextArea rows={[
                        "npm start"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceExpressJs;