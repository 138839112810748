import React, { Component } from 'react';
import './Services.css';
import imgSoftwareEntwicklung from './img/Software-Entwicklung.jpg';
import imgWebdesign from './img/Webdesign.jpg';
import imgCloud from './img/Cloud.jpg';
import injectSheet from "react-jss";
import classNames from 'classnames';
import imgSoftwareDevelopment from "./img/SoftwareDevelopment.png";
import EEFadeIn from "../../ui/eefadein/EEFadeIn";


const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

class Services extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }




  render() {



      const styles = this.props.classes
      const seperator = <div className="p-col-12" style={{borderBottom: '3px solid #1976d2', marginTop: 10, marginBottom: 10}}/>

      const CheckIcon = () => (<EEFadeIn orientation="left"><i className="fas fa-check" style={{color: '#1976d2', fontSize: '1.5em'}}/></EEFadeIn>)
        return (
            <div>
                <div className="grid">
                    <div className="col-12"><h1>SOFTWARE ENTWICKLUNG</h1></div>
                    <div className={classNames("col-12 md:col-6", styles.grid)}>
                        <div>
                            <EEFadeIn orientation="left">
                                <img width="100%" src={imgSoftwareEntwicklung}/>
                            </EEFadeIn>
                        </div>
                    </div>
                    <div className={classNames("col-12 md:col-6", styles.grid)}>
                        <div>
                            <img width="100%" style={{maxWidth: 640}} src={imgSoftwareDevelopment}/><br/><br/>
                            Sie haben eine große Menge an Daten oder möchten einen Prozess optimieren, dann benötigen Sie eine Anwendung, die den gesamten Arbeitsablauf verwaltet und automatisiert. Ich biete Ihnen <b>maßgeschneiderte Lösungen</b> mit besonders benutzerfreundliche Bedieneroberfläche an.
                        </div>
                    </div>

                    <div className={classNames("col-12", styles.grid)}>
                        <div>
                            „Verteilte Anwendungen“, „Kostenkontrolle“, „Erreichbarkeit“, „unkomplizierte Wartung“ sind in diesem Kontext Schlagwörter, die den Trend “Cloud Application“ populärer machen. Durch die Verlagerung Ihrer Anwendungen auf die Cloud sparen Sie jede Menge an Unterhalts- und Anschaffungskosten, die sonst Jahr für Jahr in neue Server-Infrastrukturen investiert werden. Diese Zeiten sind nun endlich vorbei. Die Cloud bietet Ihnen dynamische Anpassung von Performance- & Speicherkapazitäten.
                        </div>
                    </div>


                    <div className={classNames("col-12 md:col-6", styles.grid)}>
                        <div>
                            <br/><br/>
                            Die ständig wechselnde Anforderungen haben fundamentale Auswirkungen auf die Art und Weise, wie Anwendungen heute entwickelt bzw. betrieben werden müssen. Damit ich diese Ansprüche gerecht werde, organisiere ich Ihre individuelle Software in fertig konfigurierte Container. Mit der Aufhebung von externen Abhängigkeiten (Betriebssystem, Prozessor, RAM, Netzwerk etc.) werden die Applikationen von der jeweiligen Umgebung getrennt und laufen autonom gekapselt im Container. Somit biete ich Ihnen portable Anwendungen die flexibel verteilt werden können.
                         </div>
                    </div>

                    <div className={classNames("col-12 md:col-6", styles.grid)}>
                        <div>
                            <EEFadeIn orientation="right">
                                <img width="100%" src={imgCloud}/>
                            </EEFadeIn>
                        </div>
                    </div>

                    <br/><br/>
                    <div className={classNames("col-12", styles.grid)}>
                        <div>
                            <br/><br/>
                            Cloud Lösungen laufen auf dem Server und werden über den WEB-Browser bedient, somit können Sie mit jedem Gerät auf Ihre Anwendung zugreifen (Computer, Smartphone, Tablet…). Es wird nur noch der Inhalt zum Arbeitsplatz übertragen.
                            <br/><br/>
                            Profitieren Sie durch die Zentralisierung Ihrer Geschäftslogik:
                            <br/><br/>
                            <table style={{paddingLeft: 40}}>
                                <tbody>
                                    <tr><td style={{verticalAlign: 'top', paddingRight: 15}}><CheckIcon/></td><td>unbegrenzte Anzahl von Anwendern</td></tr>
                                    <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td>Zugriff auf das Programm über WEB-Browser</td></tr>
                                    <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td>zentrale Archivierung und Sicherung der Anwendungsdaten</td></tr>
                                    <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td>Reduzierung von Datenverlust beim Ausfall des Arbeitsplatzes</td></tr>
                                    <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td>Anschaffungskosten sparen, indem Sie keine teuren Computer für jeden einzelnen Arbeitsplatz kaufen</td></tr>
                                    <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td>Freie Auswahl Ihrer Arbeitsplatz (ob Büro oder Zuhause, Windows oder Mac, Computer oder Tablet, die Entscheidung liegt nur bei Ihnen)</td></tr>
                                </tbody>
                            </table>
                            <br/>
                            Durch den Einsatz einer Reihe von OpenSource-Lösungen vermeide ich unnötige laufende Kosten und garantiere für einen unkomplizierten Betrieb.
                        </div>
                    </div>
                    {seperator}

                    <div className="col-12"><h1>WEBDESIGN</h1></div>
                    <div className={classNames("col-12 md:col-6", styles.grid)}>
                        <div>
                            Ihre Webseite ist der erste Eindruck, den der Kunde über Sie/Ihr Unternehmen bekommt. Laut Statistiken gewinnen Unternehmen mit Internetpräsenz ca. 35% mehr Kunden im Vergleich zu ihren Konkurrenten, die keine Internetpräsenz haben. Ich designe Ihre Internetpräsenz zur Darstellung Ihres Unternehmens oder zur Kontaktaufnahme im World Wide Web.
                            <br/><br/>

                            <EEFadeIn orientation="left">
                                <img width="100%" src={imgWebdesign}/>
                            </EEFadeIn>
                        </div>
                    </div>
                    <div className={classNames("col-12 md:col-6", styles.grid)}>

                        <center>
                            <table style={{fontSize: 24}}>
                                <tbody>
                                <tr><td style={{verticalAlign: 'top', paddingRight: 15}}><CheckIcon/></td><td><EEFadeIn orientation="right">persönliche Konzeptberatung</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">individuelles Design</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">Inhalte selbst verwalten</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">Schulung/Einführung</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">Domain und Webhosting Bestellung</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">Server Installation</EEFadeIn></td></tr>
                                <tr><td style={{verticalAlign: 'top'}}><CheckIcon/></td><td><EEFadeIn orientation="right">Email Einrichtung</EEFadeIn></td></tr>
                                </tbody>
                            </table>
                        </center>
                    </div>
                </div>
            </div>
        )
  }
}

export default injectSheet(styles)(Services);