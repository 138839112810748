import React, { Component } from 'react';
import injectSheet from "react-jss";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";

class CompetenceSvn extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

  render() {
        return (
            <div>
                <h1>SVN</h1>

                <DescriptionPanel title="Export">
                    <CodeTextArea rows={[
                        "svnadmin dump /opt/svn > /tmp/full.dump"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Import">
                    <CodeTextArea rows={[
                        "svnadmin load /opt/svn < /tmp/full.dump"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceSvn;