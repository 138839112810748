import React, { Component } from 'react';
import injectSheet from "react-jss";

class CompetenceSpringBoot extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>SpringBoot</h1>

            </div>
        )
  }
}

export default CompetenceSpringBoot;