import React, { Component } from 'react';
import injectSheet from "react-jss";

class CompetenceJsf extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

      render() {
            return (
                <div>
                    <h1>JSF</h1>
                </div>
            )
      }
}

export default CompetenceJsf;