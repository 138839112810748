import React, { Component } from 'react';
import './Style.css';
import styles from './SocialMediaLink.module.css';
import EEFadeIn from "../../ui/eefadein/EEFadeIn";

function SocialMediaLinks(props) {

    const onLinkedinClick = () => {
        window.location.href = "https://de.linkedin.com/in/ersoy-eren-4106bb19a"
    }

    const onXingClick = () => {
        window.location.href = "https://www.xing.com/profile/Ersoy_Eren2"
    }

    const onWhatsappClick = () => {
        window.location.href = "https://api.whatsapp.com/send?phone=+4915754084582&text=Hallo+Ersoy+ich+schreibe+aus+deiner+Homepage"
    }

    const onFacebookClick = () => {
        window.location.href = "https://www.facebook.com/ersoy.eren.79/"
    }

    const onInstagramClick = () => {
        window.location.href = "https://www.instagram.com/ersoyeren86/"
    }

    return (
        <table style={{height: 70}}>
            <tbody>
            <tr>
                <td style={{width: 60, textAlign: 'center'}}><EEFadeIn orientation="left"><i type={"LINKEDIN"} className={"fab fa-linkedin " + styles.socialMediaIcon} onClick={() => onLinkedinClick()}/></EEFadeIn></td>
                <td style={{width: 60, textAlign: 'center'}}><EEFadeIn orientation="left"><i type={"XING"} className={"fab fa-xing-square " + styles.socialMediaIcon} onClick={() => onXingClick()}/></EEFadeIn></td>
                <td style={{width: 60, textAlign: 'center'}}><EEFadeIn><i type={"WHATSAPP"} className={"fab fa-whatsapp-square " + styles.socialMediaIcon} onClick={() => onWhatsappClick()}/></EEFadeIn></td>
                <td style={{width: 60, textAlign: 'center'}}><EEFadeIn orientation="right"><i type={"FACEBOOK"} className={"fab fa-facebook-square " + styles.socialMediaIcon} onClick={() => onFacebookClick()}/></EEFadeIn></td>
                <td style={{width: 60, textAlign: 'center'}}><EEFadeIn orientation="right"><i type={"INSTAGRAM"} className={"fab fa-instagram-square " + styles.socialMediaIcon} onClick={() => onInstagramClick()}/></EEFadeIn></td>
            </tr>
            </tbody>
        </table>
    )
}

export default SocialMediaLinks;