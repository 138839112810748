import React, { Component } from 'react';
import injectSheet from "react-jss";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";


const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

class CompetenceDocker extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {

        return (
            <div>
                <h1>Docker</h1>

                <DescriptionPanel title="Installation">
                    um das Docker Paket mir APT zu installieren sollten Sie vorerst sicher gehen das sie sudo rechte haben und Ihr APT aktuell ist
                    <CodeTextArea rows={[
                        "sudo –i",
                        "apt-get update"
                    ]}/>
                    nun können Sie Docker direkt vom Hersteller herunterladen und installieren
                    <CodeTextArea rows={[
                        "curl -fsSL https://get.docker.com | sh"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Image">
                    images aus Repo auschecken:
                    <CodeTextArea rows={[
                        "docker pull ubuntu"
                    ]}/><br/>

                    Eigene images per dockerfile erzeugen (es muss eine scriptdatei “dockerfile“ im ordner existieren):
                    <CodeTextArea rows={[
                        "docker build -t eecloud ."
                    ]}/><br/>

                    Bereits ausgecheckte “images“ auflisten:
                    <CodeTextArea rows={[
                        "docker images"
                    ]}/><br/>

                    images löschen
                    <CodeTextArea rows={[
                        "docker rmi ubuntu"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Container">
                    erstellen
                    <CodeTextArea rows={[
                        "docker run -it -d -p 80:80 --name=ubuntu Ubuntu /bin/bash"
                    ]}/><br/>

                    bereits gestartete auflisten
                    <CodeTextArea rows={[
                        "docker ps"
                    ]}/><br/>

                    alle auflisten
                    <CodeTextArea rows={[
                        "docker ps -a"
                    ]}/><br/>

                    starten
                    <CodeTextArea rows={[
                        "docker start ubuntu"
                    ]}/>
                    oder auch mit “ID“
                    <CodeTextArea rows={[
                        "docker start c78bf2b4f3cf"
                    ]}/>
                </DescriptionPanel>




                <h2>Nützliche Befehle</h2>
                <DescriptionPanel title="Container">
                    Shell eines Containers aufrufen
                    <CodeTextArea rows={[
                        "docker attach c78bf2b4f3cf"
                    ]}/>
                    Achtung: Beim “exit“ wird der container gestoppt. Deshalb ist es besser das man ein neues Bash startet
                    <CodeTextArea rows={[
                        "docker exec -it ubuntu bash"
                    ]}/><br/>

                    Shell-Command ausführen
                    <CodeTextArea rows={[
                        "docker exec -it ubuntu apt-update"
                    ]}/><br/>

                    aktuellen Status der laufenden “container“ anzeigen
                    <CodeTextArea rows={[
                        "docker stats"
                    ]}/><br/>

                    Dateien vom Docker nach Host kopieren
                    <CodeTextArea rows={[
                        "docker cp <containerId>:/file/path/within/container /host/path/target"
                    ]}/>
                </DescriptionPanel>





                <h2>Sichern & Wiederherstellen</h2>
                <DescriptionPanel title="Backup">
                    ein “image“ erstellen
                    <CodeTextArea rows={[
                        "docker commit ubuntu myNewUbuntuImage"
                    ]}/>
                    “image“ exportieren
                    <CodeTextArea rows={[
                        "docker save mynewimage > /home/eeren/mynewimage.tar"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Restore">
                    “image“ importieren
                    <CodeTextArea rows={[
                        "docker load < /home/eeren/mynewimage.tar"
                    ]}/>
                    “container“ erstellen
                    <CodeTextArea rows={[
                        "docker run -it -d -p 80:80 --name=ubuntu Ubuntu /bin/bash"
                    ]}/>
                </DescriptionPanel>





                <h2>Network</h2>
                <DescriptionPanel title="Einen virtuellen Netz erstellen">
                    <CodeTextArea rows={[
                        "docker network create --subnet=172.18.0.0/16 prod_net"
                    ]}/>
                    und “container“ erstellen
                    <CodeTextArea rows={[
                        "docker run -it -d --net prod_net --ip 172.18.0.10 -p 80:80 --name=ubuntu Ubuntu"
                    ]}/>
                </DescriptionPanel>





                <h2>Konfiguration</h2>
                <DescriptionPanel title="Remote API aktivieren">
                    öffnen Sie folgende Konfigurationsdatei
                    <CodeTextArea rows={[
                        "nano /lib/systemd/system/docker.service"
                    ]}/>
                    und ändern Sie folgende Einstellung
                    <CodeTextArea rows={[
                        "ExecStart=/usr/bin/dockerd -H fd:// -H tcp://0.0.0.0:2376"
                    ]}/>
                    im Anschluss müsses sie nun den Deamon & Service neustarten
                    <CodeTextArea rows={[
                        "sudo systemctl daemon-reload",
                        "sudo systemctl restart docker.service"
                    ]}/>
                </DescriptionPanel>





                <h2>Docker Compose</h2>
                <DescriptionPanel title="Installation">
                    <CodeTextArea rows={[
                        "sudo apt-get install docker-compose"
                    ]}/>
                    start docker-compose.yml
                    <CodeTextArea rows={[
                        "docker-compose up -d"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceDocker;