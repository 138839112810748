import React, {useEffect, useState} from 'react';
import TrackVisibility from "react-on-screen";



function Form(props) {


    const [time, setTime] = useState(null);
    const [text, setText] = useState('"Breites technologisches Wissen, langjährige Erfahrung in der Entwicklung von Webanwendungen und Webseiten sowie ein hoher Qualitätsanspruch sind die Grundlagen meines Handwerks"');

    useEffect(() => {
        const interval = setInterval(() => {
            if(time != null){
                setTime(time+1);
            }
        }, 20)

        return () => {
            clearInterval(interval);
        }
    }, [time])

    return (
        <div>
            <TrackVisibility once>
                {({ isVisible }) => isVisible && time == null && setTime(0)}
            </TrackVisibility>
            {text.substring(0, time)}
        </div>
    )
}

export default Form;