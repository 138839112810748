import React, { Component } from 'react';
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";


class CompetenceGit extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>GIT</h1>


                <h3>INIT</h3>
                <DescriptionPanel title="Git Remote\Bare Repository">
                    Git Remote\Bare Repository erstellen
                    <CodeTextArea rows={[
                        "git init --bare [branchname].git"
                    ]}/>
                </DescriptionPanel>

                
                <h3>WORK</h3>
                <DescriptionPanel title="Git Lokale (Working Clone)">
                    Arbeitskopie Klone für Lokale Arbeit erstellen
                    <CodeTextArea rows={[
                        "git clone ../git/[branchname].git"
                    ]}/>
                    ssh
                    <CodeTextArea rows={[
                        "git clone ssh://root@XXX.XXX.XXX.XXX/[branchname].git",
                    ]}/>
                    http
                    <CodeTextArea rows={[
                        "git clone https://XXX.XXX.XXX.XXX/[branchname].git"
                    ]}/>

                    <br/>
                    Remote Quelle anzeigen 
                    <CodeTextArea rows={[
                        "git config --get remote.origin.url"
                    ]}/>
                    
                    Remote Quelle ändern
                    <CodeTextArea rows={[
                        "git remote set-url origin https://XXX.XXX.XXX.XXX/[branchname].git"
                    ]}/>

                    <br/>
                    status anzeigen
                    <CodeTextArea rows={[
                        "git status"
                    ]}/>

                    status aller unterordner anzeigen
                    <CodeTextArea rows={[
                        "find . -maxdepth 1 -mindepth 1 -type d -exec sh -c '(echo {} && cd {} && git status -s && echo)' \\;"
                    ]}/>

                    <br/>
                    <b>push</b>
                    <CodeTextArea rows={[
                        "git add .",
                        "git commit -m \"new Verson\"",
                        "git push -u origin master"
                    ]}/>
                    Author nach commit reseten (Bsp.: weil andere Email)
                    <CodeTextArea rows={[
                        "git commit --amend --reset-author"
                    ]}/>

                    <br/>
                    <b>reset</b>
                    <CodeTextArea rows={[
                        "git add .",
                        "git reset --hard"
                    ]}/>
                </DescriptionPanel>


                <DescriptionPanel title="Push an existing folder">
                    <CodeTextArea rows={[
                        "cd existing_folder",
                        "git init",
                        "git remote add origin https://XXX.XXX.XXX.XXX/hello-world.git",
                        "git add .",
                        "git commit -m \"Initial commit\"",
                        "git push -u origin master"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Push an existing Git repository">
                    <CodeTextArea rows={[
                        "cd existing_repo",
                        "git remote rename origin old-origin",
                        "git remote add origin http://XXX.XXX.XXX.XXX/root/hello-world.git",
                        "git push -u origin --all",
                        "git push -u origin --tags"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Repository umziehen">
                    <CodeTextArea rows={[
                        "git clone https://XXX.XXX.XXX.XXX/hello-world.git",
                        "git remote rm origin",
                        "git remote add origin http://XXX.XXX.XXX.XXX/root/hello-world2.git",
                        "git push origin --all && git push --tags"
                    ]}/>
                </DescriptionPanel>

                
                <DescriptionPanel title="Branch">
                    Lokalen Branch anzeigen
                    <CodeTextArea rows={[
                        "git branch -l"
                    ]}/>
                    neuen lokalen Branch erstellen
                    <CodeTextArea rows={[
                        "git checkout -b [branchname]"
                    ]}/>
                    Lokalen Branch wechseln
                    <CodeTextArea rows={[
                        "git checkout [branchname]"
                    ]}/>
                    Lokalen Branch löschen
                    <CodeTextArea rows={[
                        "git branch -d [branchname]"
                    ]}/>
                    Remote Branch anzeigen
                    <CodeTextArea rows={[
                        "git branch -r -l"
                    ]}/>
                    Remote Branch löschen
                    <CodeTextArea rows={[
                        "git push origin -d [branchname]"
                    ]}/>
                    Remote Branch einchecken
                    <CodeTextArea rows={[
                        "git push --set-upstream origin [branchname]"
                    ]}/>


                </DescriptionPanel>
                


                <h3>CONFIG</h3>
                <DescriptionPanel title="Benutzer Informationen konfigurieren">
                    Config auflisten
                    <CodeTextArea rows={[
                        "git config --list"
                    ]}/>
                    Name
                    <CodeTextArea rows={[
                        "git config --global user.name \"Ersoy Eren\""
                    ]}/>
                    Email
                    <CodeTextArea rows={[
                        "git config --global user.email \"mail@ersoyeren.de\""
                    ]}/>
                    Credentials speichern
                    <CodeTextArea rows={[
                        "git config --global credential.helper store"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceGit;