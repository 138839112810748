import React, { Component } from 'react';
import injectSheet from "react-jss";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";

class CompetenceReact extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>React</h1>

                <DescriptionPanel title="Eine App erstellen">
                    <CodeTextArea rows={[
                        "npx create-react-app appName",
                        "cd appName",
                        "npm start"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Eine Komponente erstellen">
                    Um eine Komponente zu erstellen wird folgendes Paket benötigt
                    <CodeTextArea rows={[
                        "npm install -g create-components-react",
                    ]}/>
                    nun wechseln sie auf das gewünschte Verzeichnis und erstellen die Komponente
                    <CodeTextArea rows={[
                        "mkdir CarMenu",
                        "cd CarMenu",
                        "ccr create CarMenu"
                    ]}/>
                </DescriptionPanel>

                <h2>Einige nützliche Pakete hinzufügen</h2>
                <DescriptionPanel title="Router">
                    <CodeTextArea rows={[
                        "npm install react-router-dom --save",
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="JSS">
                    <CodeTextArea rows={[
                        "npm install react-jss --save",
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Axios">
                    <CodeTextArea rows={[
                        "npm install axios --save",
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="PrimeReact">
                    <CodeTextArea rows={[
                        "npm install primereact --save",
                        "npm install primeicons --save",
                        "npm install classnames --save",
                        "npm install primeflex --save"
                    ]}/>
                    wird für Dialog benötigt
                    <CodeTextArea rows={[
                        "npm install react-transition-group"
                    ]}/>
                    zusätzlich müssen die Ressourcen in "App.js" importiert werden
                    <CodeTextArea rows={[
                        "import 'primereact/resources/themes/nova-light/theme.css';",
                        "import 'primereact/resources/primereact.min.css';",
                        "import 'primeicons/primeicons.css'",
                        "import 'primeflex/primeflex.css'"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceReact;