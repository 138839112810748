
export let messages = {

    home: {

    },
    footer: {
        aboutMe: "...",
        aboutMeText: "Als Softwareentwickler in Frankfurt am Main plane und entwickle ich zuverlässige Lösungen für Ihre individuelle Anforderungen. Ich unterstütze Sie bei der Vorbereitung oder Initialisierung eines neuen Projektes und berate Sie bei Schwierigkeiten mit Ihrem IT-System. Meine Produktpalette umfasst Lösungen für jedes Problem. Sollte einer Ihrer Wünsche dennoch nicht aufgelistet sein, kontaktieren Sie mich, damit ich auch dafür schnellstmöglich eine Lösung finde. Ich freue mich auf Ihre Anfrage!",
        contact: "Kontakt",
        allRightsReserver: '© 2017 by Ersoy Eren',
    }
}

export const MESSAGES_EN = {

    language: 'Language',
    headerLabel: 'Your competent partner in the beverage and food wholesale and retail',
    headerText: 'With a focus on gastronomy and the hotel industry',

    aboutUs: 'We about',
    aboutUsText: 'Our Company was founded by Mr. Haki Konak, who is actively participating in the commercial life since 1990. Since the year 2015, Konak Getränke has been the main Distributor of Frankfurt Coca-Cola in the Frankfurt region, with two warehouses of 3500 square meters having a total closed space of 2500 square meters.',

    haveYouSomeQuestion: 'Do you have anymore questions?',
    contactUsPerTelOrEmail: 'Contact us by phone or email.',
    office: 'Office',
    fax: 'Fax',
    eMail: 'Email',


    ourProductRange: 'Product range',

    selectionOfDrinks: 'Extensive drinks selection',
    selectionOfDrinksText: 'Our company is also serving Italian restaurants and cafés by including food products and service packagings unique to the Italian gastronomical concept under 350 different, special brands to its assortment, alongside one main drinking water brand and 330 different brands in the beverage sector.',


    qualitativeFoods: 'Qualitative foods',
    qualitativeFoodsText: 'Konak Getränke is in a partnership and operating joint ventures with South America Investment Holdings (S.A.I.S.A), as well as having 680 different types of products in beverage and packaging sector under different brands. The company is also operating in joint ventures for importing and exporting in agricultural products particularly, soybeans, maize, and chia, as well as livestock and packed meat with ISO and Halal certificates.',


    agriculturalProducts: 'Agricultural products',
    agriculturalProductsText: 'In South America, there are joint trade initiatives with a slaughterhouse that has a capacity of slaughtering 100 animals per hour, 100,000 hectares of agricultural land for maize and soybeans, and 350,000 live bovine animals.',

    allRightsReserver: '© 2018 Konak Getränke GmbH - All rights reserved.',
    sitePolicy: 'Site policy & Data privacy'
}

export const MESSAGES_DE = {

    language: 'Sprache',
    headerLabel: 'Ihr kompetenter Partner im Getränke- und Lebensmittel Groß- und Einzelhandel',
    headerText: 'Mit Schwerpunkt in Gastronomie und Hotellerie',

    aboutUs: 'Über uns',
    aboutUsText: 'Unsere Firma wurde von Herrn Haki Konak, der seit 1990 aktiv im Handelsleben beteiligt ist, begründet. Konak-Getränke, die seit 2015 in der Region Frankfurt mit 3500 Quadratmeter, davon insgesamt 2500 Quadratmeter überdacht, 2 Stück Lagerraums verfügt, ist unter anderem auch Hauptverteiler von Coca-Cola Produkten im Rhein-Main Gebiet. Aber auch außerhalb der Region werden unsere Kunden schnell und direkt vesorgt.',

    haveYouSomeQuestion: 'Haben Sie noch Fragen?',
    contactUsPerTelOrEmail: 'Kontaktieren Sie uns telefonisch oder per E-Mail.',
    office: 'Büro',
    fax: 'Fax',
    eMail: 'Email',


    ourProductRange: 'Unser Sortiment',

    selectionOfDrinks: 'Umfangreiche Getränkeauswahl',
    selectionOfDrinksText: 'Unsere Firma bietet neben der eigenen Wassermarke weitere 330 verschiedene Produktmarken und Tätigkeiten im Getränkesektor (spezifisch der Italienischen Gastronomie). Speziell 350 verschiedenen Produktmarken, auch die Nahrungsmittelprodukte und Nahrungsmittel-Service-Verpackungen gehören zum Sortiment. Betreffenden Italienischen Restaurants und Cafés Dienstleistung.',


    qualitativeFoods: 'Qualitative Lebensmittel',
    qualitativeFoodsText: 'Konak Getränke die im Nahrungsmittel, Getränke und Verpackung Produktgruppen über 680 Stück reichhaltige verschiedene Produktmarkeninhalt verfügt, übt andererseits mit dem Südamerika Investition Holding (S.A.I.S.A) Firma Joint Venture und Geschäftspartnerschaft aus. Überwiegend mit landwirtschaftlichen Produkten wie Soja, Mais, Chia besteht betreffend Rindfleisch Import und Export, verfügend über Lebendvieh und ISO Halal-Schlacht Zertifikate, Gemeinschaftsunternehmen.',


    agriculturalProducts: 'Agrarprodukte',
    agriculturalProductsText: 'Einschließlich mit 350.000 Stück Lebendrindvieh in Südamerika verfügt es über insgesamt 100.000 Hektar landwirtschaftlichen Fläche für Mais und Soja und es bestehen mit einem Schlachthof die pro Stunde 100 Stück Lebendvieh schlachtet gemeinschaftliches Handelsunternehmen.',

    allRightsReserver: '© 2018 Konak Getränke GmbH - All rights reserved.',
    sitePolicy: 'Impressum & Datenschutz'
}

export const MESSAGES_TR = {

    language: 'Dil',
    headerLabel: 'Alkollu ve Alkolsüz içecek grupları / İtalyan Gastronomi ve Tarım ürünleri alanında yetkili cözüm ortağınız',
    headerText: 'Hotel ve Gastronomi alaninda odaklanarak',

    aboutUs: 'Hakkımızda',
    aboutUsText: 'Şirketimiz 1990 yılından itibaren ticaret hayatında aktif yer alan Sn. Haki KONAK tarafından kurulmuştur. 2015 yilindan bu yana Frankfurt bolgesinde toplamda 2500 metre karesi kapali olmak uzere 3500 metrekarelik 2 adet depoya sahip olan Konak Getränke ayrica Coca Cola Frankfurt Bolge Ana Distribitorudur .',

    haveYouSomeQuestion: 'Başka sorunuz var mı?',
    contactUsPerTelOrEmail: 'Telefonla veya e-posta ile bize ulaşın.',
    office: 'Office',
    fax: 'Fax',
    eMail: 'Email',


    ourProductRange: 'Ürün Çeşitliliğimiz',

    selectionOfDrinks: 'Genis içecek grubu',
    selectionOfDrinksText: '1 Ana su markasina sahip firmamiz icecek sektorunde 330 degisik urun markalari ile faaliyetlerinin yani sira Italyan Gastronomi anlayisina ozgu Special 350 degisik urun markasi ile gida urunlerini ve gida servis ambalajlarini da de sortimentine dahil edip ilgili Italyan Restaurantlari ve Cafe\' lere servis hizmeti saglamaktadir .',


    qualitativeFoods: 'Nitelikli gıdalar',
    qualitativeFoodsText: 'Gida, Icecek ve Ambalaj urun gruplarinda 680 adet zengin farkli urun marka icerigine sahip olan Konak Getränke, diger taraftan Guney Amerika Yatirim Holding (S.A.I. S.A) firmasi ile ortak girisim ve is partnerligi yapmaktadir. Agirlik merkezi soya misir chia gibi tarimsal urunler olmak uzere canli hayvan ve ISO ile Helal kesim sertifikatlarina sahip sigir eti ithalat ve ihracati adina ortak is girisimleri mevcuttur .',


    agriculturalProducts: 'Tarım ürünleri',
    agriculturalProductsText: 'Guney Amerika \' da 350.000 adet canli buyuk bas Hayvanciligi dahil Misir ve soya icin toplamda 100.000 hektar tarim alanlarina sahip ve saatte 100 adet Canli sigir kesimi yapan bir mezbahane ile ortak ticaret girisimleri mevcuttur .',

    allRightsReserver: '© 2018 Konak Getränke GmbH - Tüm hakları saklıdır.',
    sitePolicy: 'Site politikası & Veri gizliliği'
}

export function setLocation(location) {
    if(location.toLowerCase() === "en") {
        messages = MESSAGES_EN
    } else if(location.toLowerCase() === "de") {
        messages = MESSAGES_DE
    } else if(location.toLowerCase() === "tr") {
        messages = MESSAGES_TR
    }
}