import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';


const styles = {
    title: {fontWeight: 'bold', textDecoration: 'underline', paddingBottom: 4},
    content: {paddingLeft: 10}
}

class DescriptionPanel extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
      const styles = this.props.classes

        return (
            <div>
                <div className={styles.title}>{this.props.title}</div>
                <div className={styles.content}>
                    {this.props.children}
                </div>
                <br/>
            </div>
        )
  }
}

export default injectSheet(styles)(DescriptionPanel);