import React, { Component } from 'react';
import styles from './Form.module.css';
import classNames from 'classnames';
import {Carousel} from "primereact/carousel";
import TrackVisibility from "react-on-screen";

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            charts: [
                {
                    name: 'POS - Kassensoftware',
                    description: 'Software mit Touchbedienung und Schnittstelle für Thermodrucker sowie Kassenlade.',
                    functions: [
                    ],
                    role: 'Entwickler',
                    environment: 'Windows',
                    language: 'Java',
                    frameworks: 'SWING',
                    database: 'MySql'
                },
                {
                    name: 'IT-Support Service Tool',
                    description: 'Service Tool für IT Support.',
                    functions: [
                        'Stammdaten von alle User, Computer, Mobilfunk im Unternehmen',
                        'LDAP - Anbindung',
                        'Telefonanlage',
                        'VNC',
                    ],
                    role: 'Entwickler',
                    environment: 'Windows',
                    language: 'Java Swing',
                    frameworks: 'SWING',
                    database: 'MySql'
                },
                {
                    name: 'Zeiterfassung',
                    description: 'Android App für Zeiterfassung und Zutrittskontrolle.',
                    functions: [
                        'NFC'
                    ],
                    role: 'Entwickler',
                    environment: 'Android',
                    language: 'Java',
                    frameworks: 'Android SDK',
                    database: 'SQLite'
                },
                {
                    name: 'Care Pack - Promotion',
                    description: 'Android App für den Verkauf von Care Packs.',
                    functions: [
                        'NFC'
                    ],
                    role: 'Entwickler',
                    environment: 'Android',
                    language: 'Java',
                    frameworks: 'Android SDK',
                    database: 'Web Service Backend'
                },
                {
                    name: 'Bundesbank - Common Eurosystem Pricing Hub (CEPH)',
                    description: 'Software für Bewertung marktfähiger Wertpapiere (Sicherheiten) im Rahmen des Common Eurosystem Pricing Hub (CEPH)',
                    role: 'Entwickler / Projektleiter (Vertretung)',
                    functions: [],
                    environment: 'WebSphere',
                    language: 'Java',
                    frameworks: 'Java Enterprise Edition, Primefaces',
                    database: 'Oracle'
                },
                {
                    name: 'Bundesbank - Sektor Holding (SHS)',
                    description: 'Software zum Einsammeln von Mikrodaten der Wertpapierbesitze',
                    functions: [
                        'inländische Banken Informationen über ihre Eigenbestände',
                        'Daten werden zusammengeführt und an das Europäische Zentralbank verschickt',
                    ],
                    role: 'Entwickler',
                    environment: 'WebSphere',
                    language: 'Java',
                    frameworks: 'Java Enterprise Edition, Primefaces',
                    database: 'Oracle'
                },
                {
                    name: 'EEDESK - Management System',
                    description: 'Customer Management Software für Kleinunternehmen (Mandantenfähig).',
                    functions: [
                        'Lead',
                        'Kunde',
                        'Task(Workflow)',
                        'Email',
                        'Angebot, Rechnung & Abrechnung'
                    ],
                    role: 'Senior Entwickler',
                    environment: 'Web (Docker)',
                    language: 'Java/Kotlin, JavaScript',
                    frameworks: 'SpringBoot, React',
                    database: 'MySql'
                },
                {
                    name: 'EEDRINK - Management System',
                    description: 'Getränke Management Software für Händler u. Lieferanten.',
                    functions: [
                        'Import (Kunde, Artikel, Journal)',
                        'GEDAT Export für Getränkehersteller',
                        'Verkaufsauswertung',
                        'Lieferschein',
                        'Rechnung'
                    ],
                    role: 'Senior Entwickler',
                    environment: 'Web (Docker)',
                    language: 'Java/Kotlin',
                    frameworks: 'SpringBoot, React',
                    database: 'MySql'
                },
                {
                    name: 'DVB Bank SE - Loss App',
                    description: 'Auswertungssystem zum Verlustphasen Ermitelln von Finanzprojekte',
                    functions: [
                        'Import',
                        'Berechnung',
                        'Export'
                    ],
                    role: 'Senior Entwickler',
                    environment: 'WildFly',
                    language: 'Java/Kotlin',
                    frameworks: 'SpringBoot, React',
                    database: 'Oracle'
                }]


        }

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '600px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }


    componentDidMount() {
    }

    projectCarouselTemplate(project) {
        return (
            <center>
                <div style={{ maxWidth: 600, textAlign: 'left'}}>
                    <b>{project.name}</b><br/>
                    {project.description}<br/>


                    {project.functions.map( funct => (
                        <div  style={{paddingLeft: 10}}>- {funct}</div>
                    ))}<br/>

                    <center>
                        <table>
                            <tbody>
                            <tr>
                                <th style={{paddingRight: 10, textAlign: 'left'}}>Rolle:</th>
                                <td>{project.role}</td>
                            </tr>
                            <tr>
                                <th style={{paddingRight: 10, textAlign: 'left'}}>Systemumgebung:</th>
                                <td>{project.environment}</td>
                            </tr>
                            <tr>
                                <th style={{paddingRight: 10, textAlign: 'left'}}>Sprache:</th>
                                <td>{project.language}</td>
                            </tr>
                            <tr>
                                <th style={{paddingRight: 10, textAlign: 'left'}}>Frameworks:</th>
                                <td>{project.frameworks}</td>
                            </tr>
                            <tr>
                                <th style={{paddingRight: 10, textAlign: 'left'}}>Datenbank:</th>
                                <td>{project.database}</td>
                            </tr>
                            </tbody>
                        </table>
                    </center>
                </div>
            </center>
        );
    }



  render() {

        return (
            <div>

                <div style={{position: 'absolute',  width: '100%', textAlign: 'center', zIndex: '-1', color: '#b2ffe8'}}>
                    <i className={"fas fa-project-diagram " + styles.projectIcon}/>
                </div>
                <div className="wrapper">
                    <div className="grid">
                        <div className={classNames("col-12")}>
                            <div>
                                <div style={{textAlign: 'center'}}><h1>Projektliste</h1></div>
                                <div className="card">
                                    <TrackVisibility>
                                        <Carousel
                                            value={this.state.charts}
                                            numVisible={1}
                                            numScroll={1}
                                            responsiveOptions={this.responsiveOptions}
                                            itemTemplate={(item) => this.projectCarouselTemplate(item)}/>
                                    </TrackVisibility>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
  }
}

export default Form;