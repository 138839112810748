import React, {useEffect, useState} from 'react';
import {Route, BrowserRouter as Router, Link} from 'react-router-dom';
import './App.css';
import {messages} from "./ui/Messages";
import Header from "./ui/header/Header";
import Home from "./home/Home"
import Competencies from "./competence/Competencies"
import Impressum from "./impressum/Impressum"
import Datenschutz from "./datenschutz/Datenschutz"
import ChatBox from "./chatbox/ChatBox"

import 'primereact/resources/themes/nova/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import {Dialog} from "primereact/dialog";
import EEFadeIn from "./ui/eefadein/EEFadeIn";
import {connect, disconnect} from "./application-react-lib/chat/ChatWebSocket";

function App() {
    const [forceUpdate, setForceUpdate] = useState(true);


  return (
    <div className="app">

        <Dialog
            showHeader={false}
            visible={localStorage.getItem('cookies') != 'accept'}
            style={{maxWidth: 300}}>
            <center><b>Datenschutzeinstellungen</b></center>
            <div style={{fontSize: 12, paddingTop: 10, paddingBottom: 10}}>
                Diese Webseite verwendet Cookies, um essenzielle Funktionen zu ermöglichen, das Angebot zu verbessern
                und die Nutzung der Webseite zu analysieren. Nähere Informationen finden Sie in unseren <a
                href="/#/datenschutz">Datenschutzbestimmungen</a>.
            </div>
            <center>
                <div className="datenschutzeinstellungenButton"
                     onClick={() => {
                         localStorage.setItem('cookies', 'accept')
                         setForceUpdate(!forceUpdate)
                     }}>Alles erlauben
                </div>
                <div style={{fontSize: 12, paddingTop: 10, paddingBottom: 10}}>
                    <a href="/#/impressum">Impressum</a> • <a href="/#/datenschutz">Datenschutzbestimmungen</a>
                </div>
            </center>
        </Dialog>
        <Router>
            <Header/>

            <ChatBox/>

            <Route exact path="/" component={Home}/>
            <div className="wrapper">
                <div>
                    <Route exact path="/competencies" component={Competencies}/>
                    <Route exact path="/impressum" component={Impressum}/>
                    <Route exact path="/datenschutz" component={Datenschutz}/>
                </div>
            </div>
            <EEFadeIn>
                <iframe width="" height="400"
                        src="https://maps.google.de/maps?hl=de&q=EEPAC%20%20Am%20Kalten%20Berg%2015%20Frankfurt+am+Main&t=&z=14&ie=utf8&iwloc=b&output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                        style={{height: '400px', width: '100%', marginBottom: -5}}></iframe>
            </EEFadeIn>

            <div className="footer">
                <div className="wrapper">
                    <div className="appMargin">
                        <div className="grid">
                            <div className="col-12 md:col-8">
                                <h3>{messages.footer.aboutMe}</h3>
                                <div style={{paddingRight: 10}}>
                                    {messages.footer.aboutMeText}
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <h3>{messages.footer.contact}</h3>
                                Ersoy Eren<br/>
                                Am Kalten Berg 15<br/>
                                60435 Frankfurt am Main<br/>
                                <div style={{paddingTop: 10}}>
                                    <i className="fas fa-phone-alt" style={{paddingRight: 10}}/> 01575 40 84 582
                                </div>

                                <div style={{paddingTop: 5}}>
                                    <i className="fas fa-envelope" style={{paddingRight: 10}}/> mail@ersoyeren.de
                                </div>
                                <br/>

                                <Link
                                    to="/impressum">
                                    IMPRESSUM
                                </Link><br/>
                                <Link
                                    to="/datenschutz">
                                    DATENSCHUTZ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{background: '#191919'}}>
                <div className="wrapper" style={{padding: 20, color: 'white', textAlign: 'center'}}>
                    {messages.footer.allRightsReserver}
                </div>
            </div>
        </Router>

    </div>
  );
}

export default App;
