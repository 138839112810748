import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';
import {Chart} from 'primereact/chart';
import {Carousel} from "primereact/carousel";
import TrackVisibility from "react-on-screen";

class MyKnowledge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            charts: [
                {
                    labels: ['Java','Kotlin','SringBoot', 'JEE', 'Swing', 'Java FX', 'Android SDK'],
                    datasets: [
                        {
                            label: 'Backend',
                            backgroundColor: '#42A5F5',
                            data: [100, 100, 90, 80, 70, 70, 50, 0]
                        }
                    ]
                },
                {
                    labels: ['JavaScript', 'HTML5', 'CSS','React JS', 'Angular 2', 'JSF', 'PHP'],
                    datasets: [
                        {
                            label: 'Frontend',
                            backgroundColor: '#9CCC65',
                            data: [100, 100, 80, 80, 60, 80, 10, 0]
                        }
                    ]
                },
                {
                    labels: ['Docker', 'Kubernates'],
                    datasets: [
                        {
                            label: 'Cloud Technology',
                            backgroundColor: '#EC407A',
                            data: [90, 20, 0]
                        }
                    ]
                },
                {
                    labels: ['IntelliJ IDEA', 'Eclipse', 'Git', 'SVN', 'Gradle', 'Maven'],
                    datasets: [
                        {
                            label: 'Dev Tools',
                            backgroundColor: '#FFCA28',
                            data: [90, 80, 80, 80, 80, 80, 0]
                        }
                    ]
                },
                {
                    labels: ['Microsoft', 'Mac OS', 'Linux'],
                    datasets: [
                        {
                            label: 'Betriebssystem',
                            backgroundColor: '#7E57C2',
                            data: [100, 100, 100, 70, 50, 0]
                        }
                    ]
                }]


        }



        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '600px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '480px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }


    componentDidMount() {
    }
    chartCarouselTemplate(chart) {
        return (
            <Chart type="bar" data={chart} />
        );
    }



  render() {

        return (
            <div>
                <div className="grid">
                    <div className={classNames("col-12")}>
                        <div>
                            <center><h1>Kenntnisse</h1></center>
                            <div className="card">
                                <TrackVisibility>
                                    <Carousel
                                        value={this.state.charts}
                                        numVisible={2}
                                        numScroll={2}
                                        responsiveOptions={this.responsiveOptions}
                                        itemTemplate={(item) => this.chartCarouselTemplate(item)}/>
                                </TrackVisibility>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
  }
}

export default MyKnowledge;