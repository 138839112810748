import React, { Component } from 'react';

class CompetenceKotlin extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

  render() {
        return (
            <div>
                <h1>Kotlin</h1>
            </div>
        )
  }
}

export default CompetenceKotlin;