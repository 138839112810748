import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";

const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

class CompetenceGit extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>SSH-Server</h1>

                <DescriptionPanel title="Pakete installieren">
                    <CodeTextArea rows={[
                        "apt install openssl openssh-client",
                        "    openssh-server openssh-sftp-server",
                        "    ssh-import-id"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Schlüssel erzeugen">
                    Danach die fehlenden Host-Keys erzeugen mit
                    <CodeTextArea rows={[
                        "ssh-keygen -A"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Konfigurationsdatei bearbeiten">
                    nano /etc/ssh/sshd_config
                    <CodeTextArea rows={[
                        "Port 22",
                        "PasswordAuthentication yes"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="SSH-Server neu starten">
                    service ssh restart
                    <CodeTextArea rows={[
                        "* Restarting OpenBSD Secure Shell server sshd [ OK ]"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Windows-Proxy erstellen">
                    Windows PowerShell (Administrator)
                    <CodeTextArea rows={[
                        "netsh interface portproxy add v4tov4 listenport=22 listenaddress=0.0.0.0 connectport=22 connectaddress= XXX.XXX.XXX.XXX"
                    ]}/>
                </DescriptionPanel>



                <h1>Konfiguration</h1>

                <DescriptionPanel title="Konfiguration datei erstellen">
                    Create a %UserProfile%\.wslconfig
                    <CodeTextArea rows={[
                        "[wsl2]",
                        "memory=6GB",
                        "swap=0",
                        "localhostForwarding=true"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default injectSheet(styles)(CompetenceGit);