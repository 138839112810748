import React, { Component } from 'react';
import dzbank from './img/dzbank.png';
import dvbBank from './img/dvbBank.jpg';
import bundesbank from './img/bundesbank.svg';
import wisag from './img/wisag.svg';
import fhfrankfurt from './img/fhfrankfurt.svg';
import wvs from './img/wvs.jpg';
import classNames from 'classnames';
import {Card} from 'primereact/card';
import TrackVisibility from "react-on-screen";
import {Carousel} from "primereact/carousel";

class MyCareer extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }

        this.responsiveOptions = [
            {
                breakpoint: '1000px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '800px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '600px',
                numVisible: 1,
                numScroll: 1
            }
        ];
        this.cards = [
            {
                icon: "fas fa-building",
                img: dzbank,
                title: "DZ Bank",
                link: "https://www.dzbank.de",
                since: "07/2022 bis heute",
                type: "Softwareentwickler"
            },
            {
                icon: "fas fa-building",
                img: dvbBank,
                title: "DVB Bank SE",
                link: "https://www.dvbbank.com/de",
                since: "07/2019 bis 06/2022 (3 Jahr)",
                type: "Softwareentwickler"
            },
            {
                icon: "fas fa-building",
                img: bundesbank,
                title: "Deutsche Bundesbank",
                link: "https://www.bundesbank.de/",
                since: "05/2013 bis 06/2019 (6 Jahre)",
                type: "Softwareentwickler"
            },
            {
                icon: "fas fa-building",
                img: wisag,
                title: "WISAG Dienstleistungsholding",
                link: "https://www.wisag.de/",
                since: "03/2011 bis 02/2012 (1 Jahr)",
                type: "Softwareentwickler"
            },
            {
                icon: "fas fa-building",
                img: wisag,
                title: "WISAG Dienstleistungsholding",
                link: "https://www.wisag.de/",
                since: "03/2010 bis 02/2011 1( Jahr)",
                type: "Praktikum"
            },
            {
                icon: "fas fa-user-graduate",
                img: fhfrankfurt,
                title: "Fachhochschule FFM",
                link: "https://www.frankfurt-university.de/",
                since: "08/2006 bis 06/2012 (6 Jahr)",
                type: "Studium"
            },
            {
                icon: "fas fa-university",
                img: wvs,
                title: "Werner-von-Siemens-Schule",
                link: "https://wvs-ffm.de/",
                since: "08/2005 bis 06/2006 (1 Jahr)",
                type: "Fachoberschulreife"
            },
            {
                icon: "fas fa-university",
                img: wvs,
                title: "Werner-von-Siemens-Schule",
                link: "https://wvs-ffm.de/",
                since: "08/2003 bis 06/2005 (2 Jahr)",
                type: "Ausbildung"
            }
        ]
    }

    cardTemplate(card){
        return  (
            <div style={{padding: 10}}>
                <Card style={{width: '100%', height: '160px'}}>
                    <table>

                        <img height="25px" src={card.img}/>

                        <div>
                            <span style={{fontWeight: "bold", fontSize: 18}}><a href={card.link}>{card.title}</a></span>
                            <div style={{fontSize: 14}}>
                                {card.since}<br/>{card.type}
                            </div>
                        </div>
                    </table>
                </Card>
            </div>
        )
    }


    render() {


        return (
            <div className="grid">
                <div className={classNames("col-12")}>
                    <TrackVisibility>
                        <Carousel
                            value={this.cards}
                            numVisible={4}
                            numScroll={4}
                            responsiveOptions={this.responsiveOptions}
                            itemTemplate={(item) => this.cardTemplate(item)}/>
                    </TrackVisibility>
                </div>
            </div>
        )

    }
}

export default MyCareer;