import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";


const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

class CompetenceGitLab extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
      const styles = this.props.classes

        return (
            <div>
                <h1>GitLab</h1>


                <h2>Backup</h2>
                <DescriptionPanel title="Backup erstellen">
                    <CodeTextArea rows={[
                        "gitlab-rake gitlab:backup:create"
                    ]}/>
                    Ihr Backup lieg im Ordner "/var/opt/gitlab/backups"
                </DescriptionPanel>

                <h2>Wiederherstellen</h2>
                <DescriptionPanel title="backup auf Zielserver kopieren">
                    Image kopieren
                    <CodeTextArea rows={[
                        "scp 1570449887_2019_10_07_12.2.1_gitlab_backup.tar root@XXX.XXX.XXX.XXX:/docker/eepac-git-docker/var/opt/gitlab/backups/1570449887_2019_10_07_12.2.1_gitlab_backup.tar"
                    ]}/>
                    Konfiguration kopieren
                    <CodeTextArea rows={[
                        "scp /etc/gitlab/gitlab.rb root@XXX.XXX.XXX.XXX:/docker/eepac-git-docker/volume/gitlab.rb"
                    ]}/>
                    Secret kopieren
                    <CodeTextArea rows={[
                        "scp /etc/gitlab/gitlab-secrets.json root@XXX.XXX.XXX.XXX:/docker/eepac-git-docker/volume/gitlab-secrets.json"
                    ]}/>
                    nun müssen den Container starten die Dateien auf das rictige Verzeichnis kopieren
                    <CodeTextArea rows={[
                        "docker exec -it eepac-git bash",
                        "cp /volume/gitlab.rb /etc/gitlab/gitlab.rb",
                        "cp /volume/gitlab-secrets.json /etc/gitlab/gitlab-secrets.json"
                    ]}/>
                    service stopen
                    <CodeTextArea rows={[
                        "gitlab-ctl reconfigure",
                        "gitlab-ctl start",
                        "gitlab-ctl stop unicorn",
                        "gitlab-ctl stop sidekiq",
                        "gitlab-ctl status"
                    ]}/>
                    prüfe ob backup im Ziel liegt
                    <CodeTextArea rows={[
                        "ls -lart /var/opt/gitlab/backups"
                    ]}/>
                    container verlassen und import starten
                    <CodeTextArea rows={[
                        "docker exec -it eepac-git gitlab-rake gitlab:backup:restore --trace"
                    ]}/>
                    restart gitlab
                    <CodeTextArea rows={[
                        "docker exec -it eepac-git gitlab-ctl restart"
                    ]}/>
                    Prüfen
                    <CodeTextArea rows={[
                        "docker exec -it eepac-git gitlab-rake gitlab:check SANITIZE=true"
                    ]}/>


                </DescriptionPanel>


            </div>
        )
  }
}

export default injectSheet(styles)(CompetenceGitLab);