import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";


class CompetenceApache extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>Apache</h1>

                <DescriptionPanel title="Let's Encrypt Zertifizierung">

                    aktuallisieren
                    <CodeTextArea rows={[
                        "apt-get update"
                    ]}/>

                    ubuntu 14
                    <CodeTextArea rows={[
                        "sudo apt-get install python-software-properties",
                        "sudo apt-get install software-properties-common python-software-properties"
                    ]}/>

                    ubuntu 18
                    <CodeTextArea rows={[
                        "sudo apt-get install -y software-properties-common"
                    ]}/>

                    certbot Repo hinzufügen
                    <CodeTextArea rows={[
                        "sudo add-apt-repository ppa:certbot/certbot",
                        "sudo apt-get update",
                        "sudo apt-get install python3-certbot-apache"
                    ]}/>

                    Zertifikat erstellen
                    <CodeTextArea rows={[
                        "letsencrypt --apache"
                    ]}/>

                    Apache service neustarten
                    <CodeTextArea rows={[
                        "sudo service apache2 restart"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetenceApache;