import React, { Component } from 'react';
import injectSheet from "react-jss";

class CompetenceJavaScript extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>JavaScript</h1>
            </div>
        )
  }
}

export default CompetenceJavaScript;