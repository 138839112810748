import React, { Component } from 'react';
import injectSheet from "react-jss";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";

class CompetencePhp extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {

      const styles = this.props.classes

        return (
            <div>
                <h1>PHP</h1>
                <h2>Befehle </h2>
                <DescriptionPanel title="Ausgeben">
                    "Hello World!" ausgeben
                    <CodeTextArea>echo "Hello World!"</CodeTextArea>
                    "Hello World!" mit Rückgabewert
                    <CodeTextArea>print "Hello World!"</CodeTextArea>
                    <br/>
                    Die Unterschiede sind sehr gering: <br/>
                    "echo" hat keinen Rückgabewert. Es kann mehrere Parameter annehmen (obwohl eine solche Verwendung selten ist.<br/>
                    "print" hat den Rückgabewert "1". Es kann in Ausdrücken verwendet werden und kann nur ein Argument annehmen.
                    "echo" ist schneller als "print"
                    <br/>
                    <br/>
                    <CodeTextArea>
                        $variable = "Hello";<br/>
                        echo "$variable World" // Ausgabe: Hello World<br/>
                        echo '$variable World' // Ausgabe: $variable World<br/>
                    </CodeTextArea>
                    die Ausgabe von Variable ist nur mit doppelte Anführungszeichen möglich. Bein einfachen Anführungszeichen wird die Variable nicht eingebunden.
                </DescriptionPanel>







                <DescriptionPanel title="Variablen">
                    <CodeTextArea>
                        $text = "Wert"
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Konstanten">
                    <CodeTextArea>
                        define("KONSTANTE","Wert");
                    </CodeTextArea>
                    oder
                    <CodeTextArea>
                        const KONSTANTE = "Wert";
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Datentypen">
                    <CodeTextArea>
                        <table>
                            <tr><td>$text = "Wert";</td><td>// String</td></tr>
                            <tr><td>$zahl = 5;</td><td>// Integer</td></tr>
                            <tr><td>$kommazahl = 5.4</td><td>// Double</td></tr>
                            <tr><td>$wahr = true</td><td>// Boolean</td></tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Datentypkonvertierung (Type Casting)">
                    <CodeTextArea>
                        $variableA = "true";<br/>
                        $variableB = (bool) $variableA;
                    </CodeTextArea>
                    alternative kann auch folgende Funktion verwendet werden.
                    <CodeTextArea>
                        $variableA = "true";<br/>
                        $variableB = settype($variableA, "boolean");
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Dynamische Variablen Namen">
                    Sie erzeugen eine Variable mit einem String. Dieser String kann nun als Name für eine weitere Variable verwendet werde.
                    <CodeTextArea>
                        $name = "variable";<br/>
                        $$name = "Hello World";<br/>
                        echo $variable;
                    </CodeTextArea>
                    Folgender Test zeigt das beim ändern der Variable die Referenzen bestehen bleibt und es wird auf den neue Wert verweist
                    <CodeTextArea>
                        $variableA = "Eine Variable";<br/>
                        $variableB = &$variableA;<br/>
                        $variableA = "Geänderte Variable";<br/>
                        echo $variableB; // Ausgabe: "Geänderte Variable"<br/>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Hilfreiche Funktionen">
                    <CodeTextArea>
                        <tr>
                            <td>gettype($variable)</td>
                            <td>// gibt den Datentyp der Variable aus.</td>
                        </tr>
                        <tr>
                            <td>is_bool($variable)</td>
                            <td>// prüft auf Boolean</td>
                        </tr>
                        <tr>
                            <td>is_string($variable)</td>
                            <td>// prüft auf String</td>
                        </tr>
                        <tr>
                            <td>is_numeric($variable)</td>
                            <td>// prüft auf Zahl</td>
                        </tr>
                        <tr><td>is_null($variable)</td>
                            <td>// prüft auf NULL</td>
                        </tr>
                        <tr><td>isset($variable)</td>
                            <td>// prüft ob die Variable existiert. Der Rückgabewert ist false wenn die Variable NULL ist</td>
                        </tr>
                        <tr>
                            <td>empty($variable)</td>
                            <td>// ähnlich wie isset(). Der Rückgabewert ist allerdings false wenn die Variable einen leeren String oder 0 beinhaltet.</td>
                        </tr>
                        <tr>
                            <td>unset($variable)</td>
                            <td>// löscht eine Varuable um im Hauptspeicher Platz zu schaffen</td>
                        </tr>


                    </CodeTextArea>
                </DescriptionPanel>







                <h2>Externe Dateien ähnlich wie Klassen bei OOP</h2>
                <DescriptionPanel title="Datei einbinden">
                    Falls die Datei nicht vorhanden ist wird ein (E_WARNING) erzeugt.
                    <CodeTextArea>include("externeDateie.php");</CodeTextArea>
                    Alternative kann auch dieses verwendet werden. Falls die Datei nicht vorhanden ist wird ein (E_ERROR) erzeugt.
                    <CodeTextArea>require("externeDateie.php");</CodeTextArea>
                    <br/>
                    Falls man unsricher ist ob die Datei bereits eingefügt wurde kann man mit folgendem Befehl dafür sorgen das es nur einmal geschieht.
                    <CodeTextArea>include_once("externeDateie.php");</CodeTextArea>
                    Alternative
                    <CodeTextArea>require_once("externeDateie.php");</CodeTextArea>
                    <br/>
                    <b>Rückgabewert</b><br/>
                    Die eingebundene Datei kann auch ein Rückgabewert liefern
                    <CodeTextArea>$wert = require("externeDateie.php");</CodeTextArea>
                    <br/>
                    <b>Netzwerk</b><br/>
                    Um Dateien über das Netzwerk einzubinden, muss vorher in der php.ini folgende Einstellungen aktiviert sein
                    <CodeTextArea>allow_url_fopen = On</CodeTextArea>
                    <br/>
                    <b>Pfad einbinden</b><br/>
                    Sie könne auch einen kompletten Pfad einbinden<br/>
                    Windows
                    <CodeTextArea>include_path = ".:/php/includes"n</CodeTextArea>
                    Linux
                    <CodeTextArea>include_path = ".;c:\php\includes"</CodeTextArea>
                </DescriptionPanel>







                <h2>Vorderfinierte Variablen</h2>
                <DescriptionPanel>
                    <CodeTextArea>
                        <table>
                            <tr>
                                <td>$_GET</td>
                                <td>// enthält die per GET angehängte Werte</td>
                            </tr>
                            <tr>
                                <td>$_POST</td>
                                <td>// enthält die oer POST versandte Werte</td>
                            </tr>
                            <tr>
                                <td>$_COOKIE</td>
                                <td>// enthält informationen zu Cookies</td>
                            </tr>
                            <tr>
                                <td>$_REQUEST</td>
                                <td>// enthält due Informationen aus GET, POST, COOKIE</td>
                            </tr>
                            <tr>
                                <td>$_SESSION</td>
                                <td>// liefert Daten aus Session-Variablen</td>
                            </tr>
                            <tr>
                                <td>$_SERVER</td>
                                <td>// enthält Informationen über PHP-Server</td>
                            </tr>
                            <tr>
                                <td>$_ENV</td>
                                <td>// enthält Informationen über die Umgebung, in der PHP läuft</td>
                            </tr>
                            <tr>
                                <td>$_FILES</td>
                                <td>// enthält hochgeladenen Dateien</td>
                            </tr>
                            <tr>
                                <td>$GLOBALS</td>
                                <td>// enthält alle globalen Variablen</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <h2>Operator</h2>
                <DescriptionPanel title="Zahlen">
                    <CodeTextArea>
                        <table>
                            <tr>
                                <td>$erg = 7 + 3;</td>
                                <td>// 10 Addition zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 - 3;</td>
                                <td>// 4 Subtraktion zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 * 3;</td>
                                <td>// 21 Multiplikation zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 / 3;</td>
                                <td>// 2.33333333333 Division zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 % 3;</td>
                                <td>// 1 Berechnet den ganzzahligen Resteiner Division.</td>
                            </tr>
                            <tr>
                                <td>$erg = 2 ** 3;</td>
                                <td>// 8 Berechnet den Exponenz zweier Zahlen.</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                    <br/>
                    <CodeTextArea>
                        $erg = 7
                        <table>
                            <tr>
                                <td>$erg += 3;</td>
                                <td>// 10 Addition zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg -= 3;</td>
                                <td>// 4 Subtraktion zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg *= 3;</td>
                                <td>// 12 Multiplikation zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg /= 3;</td>
                                <td>// 4 Division zweier Zahlen</td>
                            </tr>
                            <tr>
                                <td>$erg %= 3;</td>
                                <td>// 1 Berechnet den ganzzahligen Rest einer Division.</td>
                            </tr>
                            <tr>
                                <td>$erg **= 3;</td>
                                <td>// 1 Berechnet den Exponenz zweier Zahlen.</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Vergleichsoperator">
                    <CodeTextArea>
                        <table>
                            <tr>
                                <td>$erg = 7 > 3</td>
                                <td>// true Größer als</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 &#60; 3</td>
                                <td>// false Kleiner als</td>
                            </tr>
                            <tr>
                                <td>$erg = 3 >= 3</td>
                                <td>// true Größer gleich</td>
                            </tr>
                            <tr>
                                <td>$erg = 3 &#60;= 3</td>
                                <td>// true Kleiner gleich</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 == 3</td>
                                <td>// false Gleichheit</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 != 3</td>
                                <td>// true Ungleichheit</td>
                            </tr>
                            <tr>
                                <td>$erg = 7 &#60;&#62; 3</td>
                                <td>// true Ungleichheit</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Genaue Gleichheit">
                    Bei diesem Vergleich wird auch der Datentyp des Werts mit einbezogen
                    <CodeTextArea>
                        <table>
                            <tr>
                                <td>$a = 3; </td>
                                <td>// Number</td>
                            </tr>
                            <tr>
                                <td>$b = "3";</td>
                                <td>// String</td>
                            </tr>
                            <tr>
                                <td>$erg = $a === $b;</td>
                                <td>// false</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Logische Operatoren">
                    <CodeTextArea>
                        <table>
                            <tr>
                                <td style={{width: "150px"}}>7 > 3 && 2 &#60; 4;</td>
                                <td>// true Logisches UND. Liefert true, wenn beide Operanden true liefern.</td>
                            </tr>
                            <tr>
                                <td style={{width: "150px"}}>7 &#60; 3 || 2 &#60; 4; </td>
                                <td>// true Logisches ODER. Liefert true, wenn einer der beiden oder beide Operanden true liefern.</td>
                            </tr>
                            <tr>
                                <td style={{width: "150px"}}>7 > 3 xor 2 &#60; 4;</td>
                                <td>// false Logisches ENTWEDER ODER. Liefert nur true, wenn einer der beiden Operanden true ist. Liefert false, wenn keiner der beiden oder beide Operanden true sind.</td>
                            </tr>
                            <tr>
                                <td style={{width: "150px"}}>!false;</td>
                                <td>// true Negation. Kehrt einen Wahrheitswert um. Aus true wird false und aus false true.</td>
                            </tr>
                        </table>
                    </CodeTextArea>
                </DescriptionPanel>







                <DescriptionPanel title="Konditionaler Operator">
                    Bedingung ? Ausdruck1 : Ausdruck2;
                    <CodeTextArea>
                        $a = 4;<br/>
                        $erg = $a != 4 ? "vier" : "nicht vier" // nicht vier
                    </CodeTextArea>
                    ab PHP 5.3
                    <CodeTextArea>
                        $a = 4;<br/>
                        $erg = $a != 4 ?: "nicht vier" // nicht vier
                    </CodeTextArea>
                    <br/>
                    nicht NULL dann wird Wert in $erg geschrieben, sonst wird Alternative geschrieben

                    <CodeTextArea rows={[
                        "$erg = $_GET['variable'] ?? 'Alternative'"
                    ]}/>
                </DescriptionPanel>







                <DescriptionPanel title="Array">
                    Sortieren
                    <CodeTextArea rows={[
                        "$sammlung = array(\"Monet\", \"Chagal\", \"Dali\", \"Manet\");",
                        "sort($sammlung);"
                    ]}/>
                </DescriptionPanel>







                <DescriptionPanel title="String">
                    String zusammenführen
                    <CodeTextArea rows={[
                        "$a   = \"Hello\"",
                        "$b   = \"World\"",
                        "$erg = $a . $b; // HelloWorld"
                    ]}/>
                    alternative
                    <CodeTextArea rows={[
                        "$erg  = \"Hello\";",
                        "$erg .= \"World\"; // HelloWorld"
                    ]}/>
                    <br/>
                    String to lowercase
                    <CodeTextArea rows={[
                        "$erg = strtolower(\"DAS ist ein TEST\") // das ist ein test"
                    ]}/>
                    <br/>
                    herodoc: geeignet für längere Strings die über mehrere Zeilen groß werden.
                    <CodeTextArea rows={[
                        "$test = <<< Inhalt",
                        "Inhalt des heredoc-Texts",
                        "über mehrere Zeilen verteilt",
                        "und ausgeweitet.",
                        "Inhalt;",
                        "echo $test;"
                    ]}/>
                    <br/>
                    nowdoc: verhält sich wie herodoc, nur dass das Schlüsselwort am Anfang in einfachen Anführungszeichen gefasst wird.
                    <CodeTextArea rows={[
                        "$test = <<<'Inhalt'",
                        "Inhalt des nowdoc-Texts",
                        "über mehrere Zeilen verteilt",
                        "und ausgeweitet.",
                        "Inhalt;",
                        "echo $test;"
                    ]}/>
                    <br/>
                    Platzhalter: mit dem folgenden Befehl geben Sie einen String aus und übergeben Platzhalter als Parameter,

                    <CodeTextArea rows={[
                        '$summe1 = 200;',
                        '$summe2 = 400;',
                        '$format = "%2\\$d Euro sind mehr als %1\\$d Euro";',
                        'printf($format, $summe1, $summe2);'
                    ]}/>

                </DescriptionPanel>





                <h2>Fallunterscheidung</h2>
                <DescriptionPanel title="IF">
                    <CodeTextArea rows={[
                        'if (Bedingung) {',
                        '   Anweisungen;',
                        '} elseif (Bedingung) {',
                        '   Anweisungen = Anweisung;',
                        '} else {',
                        '   Anweisungen;',
                        '}'
                    ]}/>
                    kurzform
                    <CodeTextArea rows={[
                        "if (Bedingung) Anweisung;"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="SWITCH">
                    <CodeTextArea rows={[
                        "switch (Variable) {",
                        "   case Wert1:",
                        "      Anweisungen;",
                        "      break;",
                        "   case Wert2:",
                        "      Anweisungen;",
                        "      break;",
                        "   case Wert3:",
                        "      Anweisungen;",
                        "      break;",
                        "   default:",
                        "      Anweisungen;",
                        "}"
                    ]}/>
                </DescriptionPanel>




                <h2>Schleifen</h2>
                <DescriptionPanel title="FOR">
                    <CodeTextArea rows={[
                        "for (Startanweisung; Bedingung; Durchlaufanweisung) {",
                        "   Anweisungen;",
                        "}",
                        "",
                        "for ($i = 0; $i < 10; $i--) {",
                        "   echo \"$i\";",
                        "}"
                    ]}/>
                    Alternative
                    <CodeTextArea rows={[
                        "$i = 0;",
                        "for (; $i < 10; ) {",
                        "   echo \"$i\";",
                        "   $i++;",
                        "}"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="WHILE">
                    <CodeTextArea rows={[
                        "while (Bedingung) {",
                        "   Anweisungen;",
                        "}",
                        "",
                        "$i = 1;",
                        "while ($i < 10) {",
                        "   echo \"$i<br />\";",
                        "   $i++;",
                        "}"
                    ]}/>
                    Verkürzung
                    <CodeTextArea rows={[
                        "$i = 1;",
                        "while($i < 10) echo $i++;"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="DOWHILE">
                    <CodeTextArea rows={[
                        "do {",
                        "   Anweisungen;",
                        "} while",
                    ]}/>
                    <br/>
                    <CodeTextArea rows={[
                        "$i = 1;",
                        "do {",
                        "   echo \"$i<br />\";",
                        "   $i++;",
                        "} while ($i < 10)"
                    ]}/>
                    BREAK<br/>
                    bricht den Schleife komplett ab<br/>
                    <br/>
                    CONTINUE<br/>
                    brich den aktuellen Schleifendurchlauf ab und macht mit dem nächsten Schleifendurchlauf weiter<br/>
                </DescriptionPanel>




                <h2>Sprünge</h2>
                <DescriptionPanel>
                    Mit goto Markenname rufen Sie die Marke auf. Sämtlicher Code dazwischen wird übersprungen.
                    <CodeTextArea rows={[
                        "goto Marke;",
                        "echo 'Ausgabe 1';",
                        "Marke:",
                        "echo 'Ausgabe 2';"
                    ]}/>
                </DescriptionPanel>




                <h2>Funktionen</h2>
                Eine Funktion sind Sammlung von Anweisungen.<br/><br/>
                <DescriptionPanel title="Der Aufbau einer Funktion">
                    <CodeTextArea rows={[
                        "function Name() {",
                        "   Anweisungen;",
                        "}"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Beispielfunktion">
                    <CodeTextArea rows={[
                        "function ausgabe() {",
                        "   echo \"Dies ist eine Funktion.\";",
                        "}",
                        "ausgabe();"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Parameter">
                    Parmater sind Informationen die man von außen an die Funktion üb ergeben kann
                    <CodeTextArea rows={[
                        "function ausgabe($par1, $par2) {",
                        "   echo \"Parameter 1: $par1<br />\";",
                        "   echo \"Parameter 2: $par2\";",
                        "}",
                        "ausgabe(\"Hallo\", \"Welt\");"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Parameter Vorgabewert">
                    Wenn nicht klar ist ob für ein Parameter immer ein Wert übergeben wird, können Sie für den Parameter auch einen Standardwert angeben
                    <CodeTextArea rows={[
                        "function ausgabe($par = \"Standard\") {",
                        "   echo \"Parameterwert: $par<br />\";",
                        "}",
                        "ausgabe();",
                        "ausgabe(\"Exklusiv\");",
                    ]}/>
                    Hinweis: Wenn mehr als ein Parameter verwendet wird, muss der Parameter mit Vorgabewert an das Ende.
                </DescriptionPanel>
                <DescriptionPanel title="Flexible Anzahl von Parametern">
                    der ...Operator steht
                    <CodeTextArea rows={[
                        "function funktion(...$params) {",
                        "   $elemente = count($params);",
                        "   echo $params[0];",
                        "   echo $params[1];",
                        "   echo $params[2];",
                        "   echo $params[3];",
                        "}",
                        "funktion(0, 1, 2, 3);",
                    ]}/>
                    alternative kann auch folgende Funktion verwendet werden. Es ist bereits seit PHP 4 vorhanden.
                    <CodeTextArea rows={[
                        "function funktion() {",
                        "   $elemente = func_num_args();",
                        "   echo $elemente;",
                        "}",
                        "funktion(\"Test\");",
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Rückgabewert">
                    <CodeTextArea rows={[
                        "function ausgabe($par) {",
                        "   return \"Hallo $par\";",
                        "}",
                        "$rueckgabe = ausgabe(\"Welt\");",
                        "echo $rueckgabe;"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Funktionsnamen in Variable">
                    <CodeTextArea rows={[
                        "function ausgabe($par) {",
                        "   echo \"Hallo $par\";",
                        "}",
                        "$funktionsname = \"ausgabe\";",
                        "$funktionsname(\"PHP 5\");"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Funktionsnamen in Variable">
                    <CodeTextArea rows={[
                        "function ausgabe($par) {",
                        "   echo \"Hallo $par\";",
                        "}",
                        "$funktionsname = \"ausgabe\";",
                        "$funktionsname(\"PHP 5\");"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Ananyme Funktionen">
                    <CodeTextArea rows={[
                        "$ausgabe = function($parameter) {",
                        "   echo 'Meine ' . $parameter . '",
                        "}",
                        "$ausgabe('Ausgabe 1');",
                        "$ausgabe('Ausgabe 2');"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Rekursive Funktionen">
                    Rekursive Funktionen rufen sich selbst auf.Sie kann ähnlich wie eine Schleife eingesetzt werden.
                    <CodeTextArea rows={[
                        "function fakultaet($i) {",
                        "   if ($i > 0) {",
                        "      return $i * fakultaet($i-1);",
                        "   } else {",
                        "      return 1;",
                        "   }",
                        "}",
                        "echo fakultaet(5);"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Generator">
                    es handelt sich dabei um Funktionen, die in einer foreach-Schleife eingesetzt werden und nicht nur einmal eine Rückgabe liefern, sondern mehrfach.
                    <CodeTextArea rows={[
                        "function addieren($start, $ende, $schritt = 1) {",
                        "   if ($start < $ende) {",
                        "      $erg = 0;",
                        "      for ($i = $start; $i <= $ende; $i += $schritt) {",
                        "         $erg += $i;",
                        "         yield $erg;",
                        "      }",
                        "   }",
                        "}",
                        "foreach (addieren(2, 10, 2) as $erg) {",
                        "   echo $erg . '<br />';",
                        "}"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Statische Variablen">
                    eine lokale Variable wird bei jedem Aufruf der Funktion neu gesetzt. Sie können allerdings eine lokale Variable auch als statische Variable anlegen. In diesem Fall bleibt der Wert einer Variable nach jedem Funktionsaufruf erhalten.
                    <CodeTextArea rows={[
                        "function fakultaet() {",
                        "   static $i = 3;",
                        "      if ($i > 0) {",
                        "         return $i-- * fakultaet();",
                        "      } else {",
                        "         return 1;",
                        "      }",
                        "   }",
                        "}",
                        "echo fakultaet();"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Typendeklaration (ab PHP 7)">
                    eine lokale Variable wird bei jedem Aufruf der Funktion neu gesetzt. Sie können allerdings eine lokale Variable auch als statische Variable anlegen. In diesem Fall bleibt der Wert einer Variable nach jedem Funktionsaufruf erhalten.
                    <CodeTextArea rows={[
                        "function summe(int ...$a): int {",
                        "   return array_sum($a);",
                        "}",
                        "$erg = summe(1, '2', 3.7);",
                        "var_dump($erg); // int(6)",
                    ]}/>
                    <br/>
                    <CodeTextArea rows={[
                        "function summe(float ...$a): float {",
                        "   return array_sum($a);",
                        "}",
                        "$erg = summe(1, '2', 3.7);",
                        "var_dump($erg); // float(6.7)"
                    ]}/>
                </DescriptionPanel>
            </div>
        )
  }
}

export default CompetencePhp;