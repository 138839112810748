import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';


const styles = {
    grid: {display: "flex", alignItems: "center", justifyContent: "center"}
}

class CompetenceAngular2 extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
      const styles = this.props.classes

        return (
            <div>
                <h1>Angular 2</h1>
            </div>
        )
  }
}

export default injectSheet(styles)(CompetenceAngular2);