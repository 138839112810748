import {_DELETE, _DOWNLOAD, _POST, _GET} from "./Api"

export const AppApi = {

    pub: {
        contact: {
            lead: {
                url: '/api/pub/contacts/leads',
                post: (content) => _POST(AppApi.pub.contact.lead.url, JSON.stringify(content)),
            }
        }
    },
}