import React, {useState} from 'react';
import styles from "./Form.module.css";
import {InputText} from "primereact/inputtext";
import EEFadeIn from "../../ui/eefadein/EEFadeIn";
import {AppApi} from "../../AppApi";

function Form(props) {

    const [isFormPost, setIsFormPost] = useState(false);
    const [isFirstnameEmpty, setIsFirstnameEmpty] = useState(false);
    const [isLastnameEmpty, setIsLastnameEmpty] = useState(false);
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isTelephoneEmpty, setIsTelephoneEmpty] = useState(false);
    const [form, setForm] = useState({
        token: 'ec90c287-199b-4232-8629-af4d38f4ba9e',
        companyName: null,
        firstName: null,
        lastName: null,
        email: null,
        telephone: null,
        fields: [
            {
                type: "TEXT",
                value: "HowHowHow",
                label: "Begüßung"
            }
        ]
    });


    const [requestFormName, setRequestFormName] = useState();



    const actions = {
        post: () => {
            if(form.firstName == null) {
                setIsFirstnameEmpty(true)
            } else {
                setIsFirstnameEmpty(false)
            }
            if(form.lastName == null) {
                setIsLastnameEmpty(true)
            } else {
                setIsLastnameEmpty(false)
            }

            if(form.email == null || !form.email.includes("@") || !form.email.includes(".")) {
                setIsEmailEmpty(true)
            } else {
                setIsEmailEmpty(false)
            }

            if(form.telephone == null) {
                setIsTelephoneEmpty(true)
            } else {
                setIsTelephoneEmpty(false)
            }

            if(form.firstName != null && form.lastName != null && form.telephone != null) {
                AppApi.pub.contact.lead.post(form)
                    .then(response => {
                        //window.location.href = "/danke/jetztKontaktAufnehmen"
                    })
                    .catch(console.log)
            }

        },
    }

    return (
        <div>

            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: 20, marginLeft: 50, height: 200, width: 200, background: '#75bcf6'}}/>
            </div>
            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: 250, marginLeft: 150, height: 30, width: 30, background: '#97bfea'}}/>
            </div>
            <div style={{position: 'absolute'}}>
                <div style={{borderRadius: 150, marginTop: 200, marginLeft: -150, height: 200, width: 200, background: '#b3d0f3'}}/>
            </div>


            <div ref={props.childRef} style={{paddingTop: 40, paddingBottom: 40}}>
                <div className="grid">
                    <div className="col-12 lg:col-3">

                    </div>
                    <div className="col-12 lg:col-6">
                        <div className={styles.contactForm}>
                            <div>

                                <h1>Jetzt Beratungstermin vereinbaren</h1>
                                <div>
                                    <div style={{paddingTop: 10, color: isFirstnameEmpty ? 'red' : null}}>Firma:</div>
                                    <InputText
                                        className="p-inputtext-lg block"
                                        style={{width: '100%'}}
                                        value={form.companyName}
                                        placeholder="Mustermann GmbH"
                                        onChange={(event) => setForm({...form, companyName: event.target.value})}/>

                                    <div className="grid">
                                        <div className="col-6">
                                            <div style={{paddingRight: 5}}>
                                                <div style={{paddingTop: 10, color: isFirstnameEmpty ? 'red' : null}}>Vorname:</div>
                                                <InputText
                                                    className="p-inputtext-lg block"
                                                    style={{width: '100%'}}
                                                    value={form.firstName}
                                                    placeholder="Max Mustermann"
                                                    onChange={(event) => setForm({...form, firstName: event.target.value})}/>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div style={{paddingTop: 10, color: isLastnameEmpty ? 'red' : null}}>Nachname:</div>
                                            <InputText
                                                className="p-inputtext-lg block"
                                                style={{width: '100%'}}
                                                value={form.lastName}
                                                placeholder="maxmustermann@mail.de"
                                                onChange={(event) => setForm({...form, lastName: event.target.value})}/>
                                        </div>
                                    </div>

                                    <div style={{paddingTop: 10, color: isEmailEmpty ? 'red' : null}}>Email:</div>
                                    <InputText
                                        className="p-inputtext-lg block"
                                        style={{width: '100%'}}
                                        value={form.email}
                                        placeholder="maxmustermann@mail.de"
                                        onChange={(event) => setForm({...form, email: event.target.value})}/>



                                    <div style={{paddingTop: 10, color: isTelephoneEmpty ? 'red' : null}}>Telefon:</div>
                                    <InputText
                                        className="p-inputtext-lg block"
                                        style={{width: '100%'}}
                                        value={form.telephone}
                                        placeholder="+49 1575 40 84 582"
                                        onChange={(event) => setForm({...form, telephone: event.target.value})}/>

                                    <div style={{paddingTop: 20}}>
                                        <EEFadeIn>
                                            <div
                                                className="venmediaButton"
                                                style={{width: '100%'}}
                                                onClick={() => actions.post()}>
                                                Absenden
                                            </div>
                                        </EEFadeIn>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 lg:col-3">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form;