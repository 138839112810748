import React, { Component } from 'react';
import injectSheet from "react-jss";
import CodeTextArea from "../ui/codetextarea/CodeTextArea";
import DescriptionPanel from "../ui/descriptionpanel/DescriptionPanel";

class CompetenceLinux extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }



  render() {
        return (
            <div>
                <h1>Linux</h1>

                <h2>Systemverzeichnisse</h2>
                <tabel>
                    <tr><td style={{width: 50}}>bin</td><td>System Programme die ausführbar sind</td></tr>
                    <tr><td>sbin</td><td></td></tr>
                    <tr><td>dev</td><td>geräte treiber</td></tr>
                    <tr><td>etc</td><td>Konfiguration</td></tr>
                    <tr><td>opt</td><td>optionale programme</td></tr>
                    <tr><td>usr</td><td>user spezifische intallations und konfiguratinsdateien</td></tr>
                    <tr><td>var</td><td>verschiedenes</td></tr>
                </tabel><br/><br/>





                <h2>Network</h2>
                <DescriptionPanel title="Firewall ausschalten">
                    <CodeTextArea rows={[
                        "ufw disable"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="offene ports anzeigen">
                    <CodeTextArea rows={[
                        "netstat -antp"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="alle ports anzeigen">
                    <CodeTextArea rows={[
                        "sudo netstat -peanut"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="Ip addresse ändern">
                    öffnen Sie folgende Konfigurationsdatei
                    <CodeTextArea rows={[
                        "sudo nano /etc/network/interfaces"
                    ]}/>
                    und stellen Sie folgendes ein
                    <CodeTextArea rows={[
                        "auto eth0",
                        "iface eth0 inet static",
                        "address 10.0.1.10",
                        "netmask 255.255.255.0",
                        "gateway 10.0.1.1",
                        "dns-nameserver 10.0.1.1",
                    ]}/>
                </DescriptionPanel>





                <h2>Archiv</h2>
                <DescriptionPanel title="entpacken">
                    <CodeTextArea rows={[
                        "tar -xvf Datei.tar.gz"
                    ]}/>
                </DescriptionPanel>
                <DescriptionPanel title="archivieren">
                    <CodeTextArea rows={[
                        "tar -czf meinOrdner.tar.gz meinOrdner/"
                    ]}/>
                </DescriptionPanel>





                <h2>Nützliche Befehle</h2>
                <DescriptionPanel title="sudo rechte holen">
                    <CodeTextArea rows={[
                        "sudo –i"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="copy">
                    Datei kopieren
                    <CodeTextArea rows={[
                        "cp dateiName.txt /home/user/dateiName.txt"
                    ]}/>
                    Ordner kopieren
                    <CodeTextArea rows={[
                        "cp –r quelle/ /home/user/ziel/"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Passwort ändern">
                    <CodeTextArea rows={[
                        "passwd benutzername"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Datei suchen">
                    <CodeTextArea rows={[
                        "whereis dateiname.txt"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Ordner Größe anzeigen">
                    <CodeTextArea rows={[
                        "du -h ordnerName"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="Anzahl der Dateien im Aktuellen Verzechnis anzeigen">
                    <CodeTextArea rows={[
                        "ls | wc -l"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="aktuell installiertes anzeigen">
                    <CodeTextArea rows={[
                        "which ls"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="aktuell laufende Prozesse anzeigen">
                    <CodeTextArea rows={[
                        "ps aux | less"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="DISK anzeigen">
                    <CodeTextArea rows={[
                        "sudo fdisk -l"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="DISK einbinden">
                    <CodeTextArea rows={[
                        "sudo mount /dev/sda2 /opt/disk/"
                    ]}/>
                </DescriptionPanel>

                <DescriptionPanel title="eine Befehl in Schleife immer wieder ausführen">
                    <CodeTextArea rows={[
                        "watch -n1 docker ps -a"
                    ]}/>
                </DescriptionPanel>




                <h2>Enviroment-Variable</h2>
                <DescriptionPanel title="Enviroment-Variable setzen">
                    folgende konfiguration editieren
                    <CodeTextArea>nano /etc/environment</CodeTextArea>
                    Variable einfügen
                    <CodeTextArea>JAVA_HOME="/usr/lib/jvm/java-8-oracle"</CodeTextArea>
                    System neustarten bzw. Einstellungen neu laden
                    <CodeTextArea>source /etc/environment</CodeTextArea>
                </DescriptionPanel>


                <h2>SSH</h2>

                <DescriptionPanel title="Enable root login (Ubuntu)">
                    make Sudo
                    <CodeTextArea rows={[
                        "sudo -i"
                    ]}/>
                    nano /etc/ssh/sshd_config
                    <CodeTextArea rows={[
                        "FROM:",
                        "#PermitRootLogin prohibit-password",
                        "TO:",
                        "PermitRootLogin yes"
                    ]}/>
                    restart SSH service
                    <CodeTextArea rows={[
                        "systemctl restart sshd",
                        "OR",
                        "service sshd restart"
                    ]}/>
                    set root’s password
                    <CodeTextArea rows={[
                        "passwd"
                    ]}/>

                </DescriptionPanel>

                <DescriptionPanel title="copy">
                    Beispiel
                    <CodeTextArea rows={[
                        "scp Quelldatei.bsp Benutzer@Host:ordner/Zieldatei.bsp"
                    ]}/>
                    Ordner kopieren
                    <CodeTextArea rows={[
                        "scp -r ordner Benutzer@Host:ordner"
                    ]}/>
                    <br/>
                    local to remote
                    <CodeTextArea rows={[
                        "scp -r volume root@XXX.XXX.XXX.XXX:/docker/wpEepacDe/volume"
                    ]}/>
                    remote to local
                    <CodeTextArea rows={[
                        "scp -r root@XXX.XXX.XXX.XXX:/docker/wpEepacDe/volume volume"
                    ]}/>

                </DescriptionPanel>
                <DescriptionPanel title="Tunnel">
                    <CodeTextArea rows={[
                        "ssh root@XXX.XXX.XXX.XXX -L 8080:localhost:8080"
                    ]}/>
                    remote to local
                    <CodeTextArea rows={[
                        "scp -r root@XXX.XXX.XXX.XXX:/docker/wpEepacDe/volume volume"
                    ]}/>

                </DescriptionPanel>


            </div>
        )
  }
}

export default CompetenceLinux;