import React, { Component } from 'react';
import injectSheet from "react-jss";
import classNames from 'classnames';
import CompetenceJava from "./competencies/CompetenceJava";
import CompetenceKotlin from "./competencies/CompetenceKotlin";
import CompetenceSpringBoot from "./competencies/CompetenceSpringBoot";
import CompetenceJavaScript from "./competencies/CompetenceJavaScript";
import CompetenceReact from "./competencies/CompetenceReact";
import CompetenceAngular2 from "./competencies/CompetenceAngular2";
import CompetenceJsf from "./competencies/CompetenceJsf";
import CompetencePhp from "./competencies/CompetencePhp";
import CompetenceGit from "./competencies/CompetenceGit";
import CompetenceGitLab from "./competencies/CompetenceGitLab";
import CompetenceSvn from "./competencies/CompetenceSvn";
import CompetenceDocker from "./competencies/CompetenceDocker";
import CompetenceLinux from "./competencies/CompetenceLinux";
import CompetenceWsl from "./competencies/CompetenceWsl";
import CompetenceApache from "./competencies/CompetenceApache";
import CompetenceExpressJs from "./competencies/CompetenceExpressJs";






const styles = {
    item: {color: '#000000', cursor: "pointer",
        "&:hover": { fontWeight: 'bold'}},
    selected: {color: "#1976d2", fontWeight: 'bold'}
}

class Competencies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            competencies : [
                {label: "Java", component: <CompetenceJava/>},
                {label: "Kotlin", component: <CompetenceKotlin/>},
                {label: "SprinBoot", component: <CompetenceSpringBoot/>},
                {label: "JavaScript", component: <CompetenceJavaScript/>},
                {label: "React", component: <CompetenceReact/>},
                {label: "Angular 2", component: <CompetenceAngular2/>},
                {label: "ExpressJS", component: <CompetenceExpressJs/>},
                {label: "JSF", component: <CompetenceJsf/>},
                {label: "PHP", component: <CompetencePhp/>},
                {label: "Git", component: <CompetenceGit/>},
                {label: "GitLab", component: <CompetenceGitLab/>},
                {label: "SVN", component: <CompetenceSvn/>},
                {label: "Docker", component: <CompetenceDocker/>},
                {label: "Linux", component: <CompetenceLinux/>},
                {label: "WSL", component: <CompetenceWsl/>},
                {label: "Apache", component: <CompetenceApache/>}
            ],
            selected: null
        }
    }

    componentDidMount() {
    }



  render() {
      const styles = this.props.classes
      const seperator = <div className="p-col-12" style={{borderBottom: '3px solid #000000'}}/>

        return (
            <div className="grid">
                <div className="col-fixed">
                    <h1>Kompetenzen</h1>
                    {this.state.competencies.map( (competence) => (
                        <div
                            className={this.state.selected == competence ? classNames(styles.item, styles.selected) : styles.item}
                            onClick={() => this.setState({selected: competence})}>{competence.label}</div>
                    ))}
                </div>
                <div className="p-col">
                    {this.state.selected && this.state.selected.component}
                </div>
            </div>

        )
  }
}

export default injectSheet(styles)(Competencies);